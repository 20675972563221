import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import NotFound from "../../components/NotFound";
import { postData } from "../../services/api";

function DefineNewPassword() {
  const [searchParams,] = useSearchParams();
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (searchParams.get('token')) {
      setToken(searchParams.get('token'));
    }
  }, [searchParams]);

  async function handleSubmitButtonClick(e) {
    e.preventDefault();

    try {
      var response = await postData("/resetPassword?token=" + token, {
        data: {
          password: password
        }
      });

      if (!response) return;
      setIsChanged(true);
    } catch (err) {
      alert(err.message);
    }
  }

  function handlePasswordChange(value) {
    setPassword(value);
  }

  function handleRepeatPasswordChange(value) {
    setRepeatPassword(value);
  }

  function validatePassword(text1, text2) {
    return text1 === text2 && text1.length >= 6;
  }

  return (
    <React.Fragment>
      {!token ? <NotFound message="O parametro Token não foi definido." /> :
        !isChanged ? <React.Fragment>
          <p>Indique um conjunto de 6 caracteres ou mais para alterar a palavra-chave.</p>
          <div className="row">
            <div className="col-12 mb-3">
              <label htmlFor="txtPassword" className="form-label">Indique uma palavra-chave</label>
              <input id="txtPassword" type="password" className="form-control" value={password} onChange={(e) => handlePasswordChange(e.target.value)}></input>
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="txtRepeatPassword" className="form-label">Repita a palavra-chave</label>
              <input id="txtRepeatPassword" type="password" className="form-control" value={repeatPassword} onChange={(e) => handleRepeatPasswordChange(e.target.value)}></input>
            </div>
            <div className="col-12 mb-3">
              <div className="d-grid gap-2">
                <button type="button" className="btn btn-dark" onClick={(e) => handleSubmitButtonClick(e)} disabled={!validatePassword(password, repeatPassword)}>Alterar palavra-chave</button>
              </div>
            </div>
          </div>
        </React.Fragment> : <React.Fragment>
          <div className="row">
            <div className="col">
              <p style={{ textAlign: "center" }}>A palavra-chave foi alterada com sucesso.</p>
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

export default DefineNewPassword;