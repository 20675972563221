import React from 'react';
import { Routes, Route } from "react-router-dom"
import DefineNewPassword from './pages/definir-password'
import RecoverPassword from './pages/recuperar-password';
import Contacts from './pages/contactos';
import Privacidade from './pages/privacidade';
import RemoveUser from './pages/remover-conta';
import ConfirmRemoveUser from './pages/confirmar-remover-conta';

function App() {
  return (
    <Routes>
      <Route path="/" element={<RecoverPassword />} />
      <Route path="/recuperar-password" element={<RecoverPassword />} />
      <Route path="/definir-password" element={<DefineNewPassword />} />
      <Route path="/contactos" element={<Contacts />} />
      <Route path="/privacidade" element={<Privacidade />} />
      <Route path="/remover-conta" element={<RemoveUser />} />
      <Route path="/confirmar-remover-conta" element={<ConfirmRemoveUser />} />
    </Routes>
  );
}

export default App;
