import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import NotFound from "../../components/NotFound";
import { postData } from "../../services/api";

function ConfirmRemoveUser() {
  const [searchParams,] = useSearchParams();
  const [token, setToken] = useState("");
  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (searchParams.get('token')) {
      setToken(searchParams.get('token'));
    }
  }, [searchParams]);

  async function handleSubmitButtonClick(e) {
    e.preventDefault();

    try {
      var response = await postData("/removeUser?token=" + token, { data: {} });
      if (!response) return;
      setIsRemoved(true);
    } catch (err) {
      alert(err.message);
    }
  }

  return (
    <React.Fragment>
      {!token ? (<NotFound message="O parametro Token não foi definido." />) :
        !isRemoved ? (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <p style={{ textAlign: "center" }}>Vamos proceder à remoção dos seus dados pessoais, esta operação <strong>NÃO PODE SER REVERTIDA</strong>. Carregue no botão abaixo para continuar.</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="d-grid gap-2">
                  <button type="button" className="btn btn-dark" onClick={(e) => handleSubmitButtonClick(e)}>Remover os meus dados pessoais</button>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <p style={{ textAlign: "center" }}>Os seus dados pessoais foram removidos com sucesso.</p>
              </div>
            </div>
          </React.Fragment>
        )}
    </React.Fragment>
  );
}

export default ConfirmRemoveUser;
