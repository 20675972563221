import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import NotFound from "../../components/NotFound";
import { getData } from "../../services/api";

function Contacts() {
  const [searchParams, ] = useSearchParams();
  const [STAPE, setSTAPE] = useState('');
  const [data, setData] = useState({});

  useEffect(() => {
    if (searchParams.get('STAPE')) {
      setSTAPE(searchParams.get('STAPE'));
      getParishData();
    }
  }, [searchParams]);

  async function getParishData() {
    try {
      var response = await getData('/getParishInfo?STAPE=' + STAPE);
      if (!response) return;
      setData(response);
    } catch (err) { }
  }

  return (
    <React.Fragment>
      {
        (!STAPE || Object.keys(data).length === 0) ? <NotFound message="Não foram encontrados dados relativos a esta Freguesia." /> : <React.Fragment>
          <div className="row">
            <div className="col-12 mb-3">
              <h3 style={{ textAlign: "center" }}>{data.parishName}</h3>
            </div>
            <div className="col-12 mb-1">
              <p><strong>Morada: </strong>{data.address}</p>
            </div>
            <div className="col-12 mb-1">
              <p><strong>Telefone: </strong><a href={"tel:" + data.phone}>{data.phone}</a></p>
            </div>
            <div className="col-12 mb-1">
              <p><strong>Email: </strong><a href={"mailto:" + data.email}>{data.email}</a></p>
            </div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
}

export default Contacts;