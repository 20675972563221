const isDebug = window.location.hostname.split(".")[0] === "localhost";

// Get the app base url
function getBaseURL() {
  if (isDebug) {
    return "https://localhost:44305";
  } else {
    return ("//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/api");
  }
}

async function getData(url) {
  const promise = new Promise((resolve, reject) => {
    fetch(getBaseURL() + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })    
      .then((response) => {
        if (!response.ok) {
          reject(new Error("Invalid response"));
        } else {
          resolve(response.json());
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

	return promise;
}

async function postData(url, data) {
  let _data = data;

  if (data.data) {
    _data = data.data;
  }

  const promise = new Promise((resolve, reject) => {
    fetch(getBaseURL() + url, {
      method: "POST",
      body: transform_json_to_urlcoded(_data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", 
      },
    })
      .then((response) => {
        if (!response.ok) {
          reject(new Error("Invalid response"));
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

	return promise;
}

function transform_json_to_urlcoded(data) { 
  var str = []; 
  for (var p in data) { 
    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p])); 
  } 
 
  return str.join("&"); 
} 

export { getData, postData };
