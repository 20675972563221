import React from "react";

function Header() {
    return (
      <React.Fragment>
        <div style={{textAlign: "center"}} className="mb-4">
            <img src="https://fresoft.pt/wp-content/uploads/2017/06/logo-minha-rua-229x300.png" alt="A minha rua" />
        </div>
      </React.Fragment>
    );
  }
  
  export default Header;