import React from "react";

function NotFound(props) {
    return (
      <React.Fragment>
        <p style={{textAlign: "center"}}>{props.message}</p>
      </React.Fragment>
    );
  }
  
  export default NotFound;