import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import { postData } from '../../services/api';
import { checkIfIsValidEmail } from "../../services/helpers";

function RemoveUser() {
  const [searchParams,] = useSearchParams();
  const [showEmail, setShowEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [markSent, setMarkSent] = useState(false);

  useEffect(() => {
    if (searchParams.get("email")) {
      if (checkIfIsValidEmail(searchParams.get("email"))) {
        setShowEmail(false);
        setEmail(searchParams.get("email"));
      }
    }
  }, [searchParams]);


  function handleEmailChange(email) {
    setEmail(email);
  }

  async function handleSubmitButtonClick(e) {
    e.preventDefault();

    try {
      var response = await postData("/sendEmailRemoveUser", {
        data: {
          email: email
        }
      });
      if (!response) return;
      setMarkSent(true);
    } catch (err) {
      alert(err.message);
    }
  }

  return (
    <React.Fragment>
      {!markSent ? (
        <React.Fragment>
          <p>Esta opção irá remover todos os seus dados pessoais da nossa base de dados. Ao aceitar iremos enviar-lhe um email de confirmação que deverá aceitar. Ao receber este email e ao aceitar <strong>TODOS OS SEUS DADOS PESSOAIS SERÃO REMOVIDOS</strong>.</p>

          {(!showEmail) ? <></> :
            <React.Fragment>
              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="txtEmail" className="form-label">Indique o seu email</label>
                  <input id="txtEmail" type="text" className="form-control" value={email} onChange={(e) => handleEmailChange(e.target.value)}></input>
                </div>
              </div>
            </React.Fragment>
          }

          <div className="row">
            <div className="col">
              <div className="d-grid gap-2">
                <button type="button" className="btn btn-dark" onClick={(e) => handleSubmitButtonClick(e)}>Enviar email</button>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="col">
              <p style={{ textAlign: "center" }}>O email foi enviado para {email}</p>
            </div>
          </div>
        </React.Fragment>
      )
      }
    </React.Fragment>
  )
}

export default RemoveUser